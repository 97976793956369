import React from 'react';
import styled from '@emotion/styled';

import Headings from '@narative/gatsby-theme-novela/src/components/Headings';
import Image, {
  ImagePlaceholder,
} from '@narative/gatsby-theme-novela/src/components/Image';

import mediaqueries from '@narative/gatsby-theme-novela/src/styles/media';
import { IArticle, IAuthor } from '@narative/gatsby-theme-novela/src/types';

import ArticleAuthors from './Article.Authors';

interface ArticleHeroProps {
  article: IArticle;
  authors: IAuthor[];
}

const ArticleHero: React.FC<ArticleHeroProps> = ({ article, authors }) => {
  const hasCoAUthors = authors.length > 1;
  const hasHeroImage =
    article.hero &&
    Object.keys(article.hero.full).length !== 0 &&
    article.hero.full.constructor === Object;

  return (
    <Hero>
      <Header>
        <HeroHeading>{article.title}</HeroHeading>
        <HeroSubtitle hasCoAUthors={hasCoAUthors}>
          <ArticleAuthors authors={authors} />
          <ArticleMeta hasCoAUthors={hasCoAUthors}>
            {/* article.date} · {article.timeToRead} min read */}
          </ArticleMeta>
          <a href="#footer">
            <Button
              type="submit"
              //   hasError={error}
              // subscribed={false}
              // disabled={true}
            >
              <CheckMarkIcon color="#009900" />
              &nbsp; BOOK NOW
            </Button>
          </a>
        </HeroSubtitle>
      </Header>
      <HeroImage id="ArticleImage__Hero">
        {hasHeroImage ? (
          <Image src={article.hero.full} />
        ) : (
          <ImagePlaceholder />
        )}
      </HeroImage>
    </Hero>
  );
};

export default ArticleHero;

const Hero = styled.div`
  ${(p) => mediaqueries.phablet`
    &::before {
      content: "";
      width: 100%;
      height: 20px;
      background: ${p.theme.colors.primary};
      position: absolute;
      left: 0;
      top: 0;
      transition: ${p.theme.colorModeTransition};
    }

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      background: ${p.theme.colors.background};
      position: absolute;
      left: 0;
      top: 10px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      transition: ${p.theme.colorModeTransition};
    }
  `}
`;

const ArticleMeta = styled.div<{ hasCoAUthors: boolean }>`
  margin-left: ${(p) => (p.hasCoAUthors ? '10px' : '0')};

  ${mediaqueries.phablet`
    margin-left: 0;
  `}
`;

const Header = styled.header`
  position: relative;
  z-index: 10;
  margin:100px auto 120px;
  padding-left: 68px;
  max-width: 749px;

  ${mediaqueries.desktop`
    padding-left: 53px;
    max-width: calc(507px + 53px);
    margin: 100px auto 70px;
  `}

  ${mediaqueries.tablet`
    padding-left: 0;
    margin: 100px auto 70px;
    max-width: 480px;
  `}

  ${mediaqueries.phablet`
    margin: 170px auto 180px;
    padding: 0 40px;
  `}

  @media screen and (max-height: 700px) {
    margin: 100px auto;
  }
`;

const HeroHeading = styled(Headings.h1)`
  font-size: 48px;
  font-family: ${(p) => p.theme.fonts.serif};
  margin-bottom: 25px;
  font-weight: bold;
  line-height: 1.32;

  ${mediaqueries.tablet`
    margin-bottom: 20px;
    font-size: 36px;
  `}

  ${mediaqueries.phablet`
    font-size: 32px;
  `}
`;

const HeroSubtitle = styled.div<{ hasCoAUthors: boolean }>`
  position: relative;
  display: flex;
  font-size: 18px;
  color: ${(p) => p.theme.colors.grey};

  ${(p) => mediaqueries.phablet`
    font-size: 14px;

    ${p.hasCoAUthors &&
      `
        &::before {
          content: '';
          position: absolute;
          left: -20px;
          right: -20px;
          top: -10px;
          bottom: -10px;
          border: 1px solid ${p.theme.colors.horizontalRule};
          opacity: 0.5;
          border-radius: 5px;
        }
    `}


    strong {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }
  `}

  /* ${(p) => mediaqueries.phablet`
    font-size: 14px;
    flex-direction: column;

    ${p.hasCoAUthors &&
      `
        &::before {
          content: '';
          position: absolute;
          left: -20px;
          right: -20px;
          top: -10px;
          bottom: -10px;
          border: 1px solid ${p.theme.colors.horizontalRule};
          opacity: 0.5;
          border-radius: 5px;
        }
    `}


    strong {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }
  `} */
`;

const HeroImage = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 944px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 30px 60px -10px rgba(0, 0, 0, 0.2),
    0 18px 36px -18px rgba(0, 0, 0, 0.22);

  ${mediaqueries.tablet`
    max-width: 100%;
  `}

  ${mediaqueries.phablet`
    margin: 0 auto;
    width: calc(100vw - 40px);
    height: 220px;

    & > div {
      height: 220px;
    }
`}
`;

const Button = styled.div`
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 38px;
  margin-left: 15px;
  border: 2px solid
    ${(p) => (p.hasError ? p.theme.colors.error : p.theme.colors.grey)};
  color: ${(p) =>
    p.hasError ? p.theme.colors.error : p.theme.colors.background};
  background: ${(p) => p.theme.colors.grey};
  font-weight: 600;
  border-radius: 35px;
  letter-spacing: 0.42px;
  transition: border-color 0.2s var(--ease-in-out-quad),
    background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);
  &:hover {
    background: ${(p) => p.theme.colors.accent};
    color: ${(p) => p.theme.colors.background};
  }
  /* ${(p) => mediaqueries.tablet`
    position: relative;
    height: 32px;
    width: 120px;
    top: 0;
    left: 0;
    border: none;
    border-radius: 0;
    border-top: 1px solid ${p.theme.colors.horizontalRule};
    &:hover {
      color: initial;
      background: initial;
    }
  `} */
  /* ${(p) => mediaqueries.tablet`
    position: relative;
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    border: none;
    border-radius: 0;
    border-top: 1px solid ${p.theme.colors.horizontalRule};
    &:hover {
      color: initial;
      background: initial;
    }
  `} */
`;

// const Button1 = styled.button<{ hasError: string }>`
//   /* position: absolute; */
//   /* left: 306px; */
//   top: 3px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 161px;
//   height: 38px;
//   border: 2px solid
//     ${(p) => (p.hasError ? p.theme.colors.error : p.theme.colors.grey)};
//   color: ${(p) => (p.hasError ? p.theme.colors.error : p.theme.colors.grey)};
//   /* background: ${(p) =>
//     p.subscribed ? p.theme.colors.grey : 'transparent'}; */
//   background: ${(p) => p.theme.colors.grey};
//   font-weight: 600;
//   border-radius: 35px;
//   letter-spacing: 0.42px;
//   transition: border-color 0.2s var(--ease-in-out-quad),
//     background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);
//   &:hover {
//     background: ${(p) =>
//       p.hasError ? p.theme.colors.error : p.theme.colors.accent};
//     color: ${(p) => p.theme.colors.background};
//   }
//   &[disabled] {
//     cursor: not-allowed;
//   }
//   svg * {
//     fill: ${(p) => p.theme.colors.background};
//   }
//   ${(p) => mediaqueries.tablet`
//     position: relative;
//     height: 60px;
//     width: 100%;
//     top: 0;
//     left: 0;
//     border: none;
//     border-radius: 0;
//     border-top: 1px solid ${p.theme.colors.horizontalRule};
//     &:hover {
//       color: initial;
//       background: initial;
//     }
//   `}
// `;

const CheckMarkIcon = ({ color: string = '#006600' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
      stroke="#006600"
      fill="#006600"
    />
  </svg>
);
